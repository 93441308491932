export const menu = [
  {
    title: "Бургер с телешко кюфте",
    category: ["burger-menu"],
    price: 7.5,
    priceSmall: 11.5,
    priceBig: 13.5,
    desc: "сочно телешко кюфте, айсберг, слайс кашкавал, домат, майонеза, кетчуп",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/burger-menu.png",
    isMenu: true,
  },
  {
    title: "Бургер с пилешко кюфте",
    category: ["burger-menu"],
    price: 6.5,
    priceSmall: 10.5,
    priceBig: 12.5,
    desc: "сочно пилешко кюфте, айсберг, слайс кашкавал, домат, майонеза, кетчуп",
    allergens: "Глутен, горчица, мляко/лактоза, сусам, яйца",
    image: "images/burger-menu.png",
    isMenu: true,
  },
  {
    title: "Бургер със свинско кюфте",
    category: ["burger-menu"],
    price: 6.5,
    priceSmall: 10.5,
    priceBig: 12.5,
    desc: "сочно свинско кюфте, айсберг, слайс кашкавал, домат, майонеза, кетчуп",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/burger-menu.png",
    isMenu: true,
  },
  {
    title: "Бургер с картофено кюфте",
    category: ["burger-menu"],
    price: 5.5,
    priceSmall: 9,
    priceBig: 11,
    desc: "картофено кюфте, айсберг, слайс кашкавал, домат, майонеза, кетчуп",
    allergens: "Глутен, мляко/лактоза, сусам",
    image: "images/burger-potato.png",
    isMenu: true,
  },
  {
    title: "Бейкън бургер",
    category: ["burger-menu"],
    price: 8.5,
    priceSmall: 12.5,
    priceBig: 14.5,
    desc: "телешко кюфте, бекон, айсберг, кисела краставичка, слайс кашкавал, майонеза, кетчуп",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/bacon-burger.png",
    isMenu: true,
  },
  {
    title: "Тортила с пиле",
    category: ["burger-menu"],
    price: 6.5,
    priceSmall: 10.5,
    priceBig: 12.5,
    desc: "пилешко филе, айсберг, слайс кашкавал, домат, майонеза, кетчуп",
    allergens: "Глутен, горчица, мляко/лактоза, сусам, фъстъци, целина, яйца",
    image: "images/tortilla.png",
    isMenu: true,
  },
  {
    title: "Криспи чикън",
    category: ["burger-menu"],
    price: 7,
    priceSmall: 11.0,
    priceBig: 13.0,
    desc: "пилешко филе, айсберг, майонеза",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/crispy-chicken.png",
    isMenu: true,
  },
  {
    title: "Биг чийз",
    category: ["burger-menu"],
    price: 7.5,
    priceSmall: 11.5,
    priceBig: 13.5,
    desc: "кюфте от 100% ирландско телешко месо, кисела краставичка, слайс кашкавал, майонеза, кетчуп",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/big-cheese.png",
    isMenu: true,
  },
  {
    title: "Пилешки филенца",
    category: ["burger-menu"],
    price: 5,
    priceSmall: 8.5,
    priceBig: 10.5,
    desc: "с корнфлекс, 3 броя",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/chicken-strips-2.png",
    isMenu: true,
  },
  {
    title: "➕ Добавка към меню - само за 2 лева",
    category: ["burger-menu"],
    desc: "3 пилешки хапки или лучени кръгчета",
  },
  {
    title: "Чийзбургер",
    category: ["popular", "burgers"],
    price: 3.3,
    priceSmall: 6.7,
    priceBig: 8.9,
    desc: "пилешко кюфте, кисела краставичка, слайс кашкавал, майонеза, кетчуп",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/cheese-burger.png",
    isMenu: true,
  },
  {
    title: "Хамбургер",
    category: ["popular", "burgers"],
    price: 3,
    priceSmall: 6.6,
    priceBig: 8.8,
    desc: "пилешко кюфте, кисела краставичка, майонеза, кетчуп",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/hamburger.png",
    isMenu: true,
  },
  {
    title: "Мини чикън бургер",
    category: ["popular", "burgers"],
    price: 3.5,
    priceSmall: 7,
    priceBig: 9,
    desc: "пилешко кюфте, айсберг, майонеза",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/chicken-burger.png",
    isMenu: true,

  },
  {
    title: "Мини картофен бургер",
    category: ["popular", "burgers"],
    price: 3.5,
    priceSmall: 7,
    priceBig: 9,
    desc: "картофено кюфте, айсберг, майонеза",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/mini-potato-burger.png",
    isMenu: true,

  },
  {
    title: "Кесадия",
    category: ["burgers"],
    price: 4.0,
    desc: "шунка, слайсове кашкавал",
    allergens: "Глутен, горчица, мляко/лактоза",
    image: "images/quesadilla.png",
  },
  {
    title: "Тост с шунка и кашкавал",
    category: ["burgers"],
    price: 4,
    desc: "шунка и кашкавал",
    allergens: "Глутен, мляко/лактоза, сусам",
    image: "images/toast.png",
  },
  {
    title: "Тост с пикантен салам",
    category: ["burgers"],
    price: 4,
    desc: "пикантен салам и кашкавал",
    allergens: "Глутен, горчица, мляко/лактоза, сусам",
    image: "images/toast-spicy.png",
  },
  {
    title: "Пържени картофки",
    category: ["fries"],
    price: 4,
    weight: "200гр",
    image: "images/french-fries.png",
  },
  {
    title: "Картофено кюфте",
    category: ["fries"],
    price: 2,
    weight: "100гр",
    desc: "1 бр.",
    image: "images/potato.png",
  },
  {
    title: "Пилешки хапки",
    category: ["fries"],
    price: 4.5,
    desc: "4 бр.",
    allergens: "Глутен, горчица, мляко/лактоза, фъстъци, яйца",
    image: "images/chicken-strips.png",
  },
  {
    title: "Пилешки хапки",
    category: ["fries"],
    price: 6,
    desc: "7 бр.",
    allergens: "Глутен, горчица, мляко/лактоза, фъстъци, яйца",
    image: "images/chicken-strips.png",
  },
  {
    title: "Пилешки хапки",
    category: ["fries"],
    price: 7.5,
    desc: "9 бр.",
    allergens: "Глутен, горчица, мляко/лактоза, фъстъци, яйца",
    image: "images/chicken-strips.png",
  },
  {
    title: "Лучени кръгчета",
    category: ["fries"],
    price: 3.5,
    desc: "5 бр.",
  },
  {
    title: "Салата Цезар",
    category: ["salats"],
    price: 7.5,
    weight: "300гр",
    desc: "айсберг, чери домати, пушено пилешко филе, пармезан, крутони",
    image: "images/salad-caesar.png",
  },
  {
    title: "Салата Томатини",
    category: ["salats"],
    price: 5.5,
    weight: "300гр",
    desc: "чери домати, Филаделфия, босилек",
    image: "images/salad-tomato.png",
  },
  {
    title: " Кетчуп",
    category: ["dressings"],
    price: 0.5,
  },
  {
    title: "Майонеза",
    category: ["dressings"],
    price: 0.5,
  },
  {
    title: "Барбекю",
    category: ["dressings"],
    price: 0.5,
  },
  {
    title: "Горчица",
    category: ["dressings"],
    price: 0.5,
  },
  {
    title: "🧊☕️ Фрапе",
    category: ["cold-drinks"],
    price: 3.5,
    weight: "400мл",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🧊🥛☕️ Студено капучино",
    category: ["cold-drinks"],
    price: 3.5,
    weight: "400мл",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🧊🥛☕️ Студено капучино с вкус",
    category: ["cold-drinks"],
    price: 4,
    weight: "400мл",
    desc: "ванилия, кокос, лешник, карамел",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🍦🥛 Шейк",
    category: ["cold-drinks"],
    price: 3,
    weight: "300мл",
    desc: "малък млечен сладоледен шейк с вкус ванилия/шоколад/ягода/захарен памук",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🍦🥛 Шейк",
    category: ["cold-drinks"],
    price: 4,
    weight: "400мл",
    desc: "голям млечен сладоледен шейк с вкус ванилия/шоколад/ягода/захарен памук",
    allergens: "Мляко/лактоза",
  },
  {
    title: "☕️🍦 Кафе със сладолед",
    category: ["cold-drinks"],
    price: 3,
    weight: "200мл",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🥤 Безалкохолни напитки",
    category: ["cold-drinks"],
    price: 2.5,
    weight: "250мл",
    desc: "Coca-Cola / Pepsi / Pepsi Zero Sugar / Mirinda / 7up",
  },
  {
    title: "🥤 Безалкохолни напитки",
    category: ["cold-drinks"],
    price: 3,
    weight: "350мл",
    desc: "Coca-Cola / Pepsi / Pepsi Zero Sugar / Mirinda / 7up",
  },
  {
    title: "🥤 Безалкохолни напитки",
    category: ["cold-drinks"],
    price: 3.5,
    weight: "500мл",
    desc: "Coca-Cola / Pepsi / Pepsi Zero Sugar / Mirinda / 7up",
  },
  {
    title: "💧 Минерална вода",
    category: ["cold-drinks"],
    price: 1.5,
    weight: "500мл",
  },
  {
    title: "💨💧 Газирана вода",
    category: ["cold-drinks"],
    price: 2,
  },
  {
    title: "🧋 Бъбъл Тий",
    category: ["cold-drinks"],
    price: 5,
    weight: "400мл",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🍋 Лимонада",
    category: ["cold-drinks"],
    price: 4,
    weight: "400мл",
  },
  {
    title: "☕️ Кафе",
    category: ["hot-drinks"],
    price: 2,
    weight: "50мл",
  },
  {
    title: "🥛☕️ Капучино",
    category: ["hot-drinks"],
    price: 2.2,
    weight: "180мл",
    allergens: "Мляко/лактоза",
  },
  {
    title: "Лате макиато",
    category: ["hot-drinks"],
    price: 2.2,
    weight: "180мл",
    allergens: "Мляко/лактоза",
  },
  {
    title: "3 в 1",
    category: ["hot-drinks"],
    price: 1,
    weight: "150мл",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🍫☕️ Горещ шоколад",
    category: ["hot-drinks"],
    price: 1.8,
    weight: "150мл",
    desc: "с или без мляко",
    allergens: "Мляко/лактоза",
  },
  {
    title: "Нескафе с мляко",
    category: ["hot-drinks"],
    price: 1.5,
    weight: "150мл",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🫖 Чай",
    category: ["hot-drinks"],
    price: 1.5,
    weight: "240мл",
  },
  {
    title: "🍊 Портокал",
    category: ["fresh-drinks"],
    price: 4.2,
    weight: "300мл",
  },
  {
    title: "🍊 Портокал",
    category: ["fresh-drinks"],
    price: 5.5,
    weight: "500мл",
  },
  {
    title: "🍊 Портокал",
    category: ["fresh-drinks"],
    price: 11,
    weight: "1л",
  },
  {
    title: "🍎 Ябълка",
    category: ["fresh-drinks"],
  },
  {
    title: "🍦 Малък",
    category: ["ice-cream"],
    price: 3,
    weight: "70гр",
    allergens: "Мляко/лактоза",
  },
  {
    title: "🍦 Голям",
    category: ["ice-cream"],
    price: 4,
    weight: "150гр",
    allergens: "Мляко/лактоза",
  },
];

export const categories = [
  /* {
    id: 0,
    title: "Нови предложения ⭐️",
    key: "new",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  }, */
  {
    id: 1,
    title: "Популярни 🔝",
    key: "popular",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 2,
    title: "Бургер меню 🍔🍟🥤",
    key: "burger-menu",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 3,
    title: "Бургери 🍔",
    key: "burgers",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 4,
    title: "Порции 🍟",
    key: "fries",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 5,
    title: "Салати 🥗",
    key: "salats",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 6,
    title: "Сосове 🥫",
    key: "dressings",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 7,
    title: "Студени напитки 🧊",
    key: "cold-drinks",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 8,
    title: "Топли напитки ☕️",
    key: "hot-drinks",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 9,
    title: "Фрешове 🍊",
    key: "fresh-drinks",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
  {
    id: 10,
    title: "Сладоледи 🍦",
    key: "ice-cream",
    icon: "",
    desc: "",
    photo: "",
    items: [],
  },
];
export default menu;
